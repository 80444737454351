#iconButton:hover {
  background-color: transparent;
}

#menuList {
  max-height: 60vh;
  overflow-y: auto;
  width: 300px;
}

#menuList ul {
  flex-direction: column;
  padding: 0;
}

#menuList ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  padding-top: 15px;
}
#menuList ul li:hover {
  background-color: transparent;
  cursor: auto;
}

#menuList .card {
  width: 220px;
  margin-bottom: 10px;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

#menuList .media {
  height: 40px;
}

#menuList .button {
  padding: 0px;
  margin-top: 57px;
}

#menuList .button button:hover {
  background-color: #fe4d67;
}

#menuList .button a {
  color: #fff;
  padding: 10px;
}

#menuList .button p {
  width: 100%;
  text-align: center;
}

.positionList {
  z-index: 200;
}

.itemFixed {
  position: fixed;
  bottom: 0;
  background-color: #fff;
}
